import { useBoundStore } from '../store/useBoundStore';
import { produce } from 'immer';
import { AccountDataInterface } from '../hooks/useUserData/userDataInterface';

export const setActiveChannels = async (companyAccountData: AccountDataInterface) => {
  useBoundStore.setState(
    produce((state) => {
      state.channels.chat = companyAccountData?.attributes.enabled_features.chat ?? true;
      state.routing.chat.active = companyAccountData?.attributes.enabled_features.chat ?? true;
      state.channels.facebookChat = companyAccountData?.attributes.enabled_features.messenger ?? true;
      state.routing.facebookChat.active = companyAccountData?.attributes.enabled_features.messenger ?? true;
      state.channels.whatsApp = companyAccountData?.attributes.enabled_features.whatsapp ?? true;
      state.routing.whatsApp.active = companyAccountData?.attributes.enabled_features.whatsapp ?? true;
      state.channels.audio = companyAccountData?.attributes.enabled_features.audio ?? true;
      state.routing.callUs.active = companyAccountData?.attributes.enabled_features.audio ?? true;
      state.channels.video = companyAccountData?.attributes.enabled_features.video ?? true;
      state.routing.video.active = companyAccountData?.attributes.enabled_features.video ?? true;
      state.channels.callBack.active = companyAccountData?.attributes.enabled_features.callback ?? true;
      state.channels.callBack.immediateCallback = companyAccountData?.attributes.enabled_features.callback ?? true;
      state.routing.immediateCallback.active = companyAccountData?.attributes.enabled_features.callback ?? true;
      state.channels.callBack.scheduledCallback = companyAccountData?.attributes.enabled_features.callback ?? true;
      state.routing.scheduleCallback.active = companyAccountData?.attributes.enabled_features.callback ?? true;
    }),
  );
};
