import { RoutingInterface } from '../../store/routingStore/RoutingInterface';
import { useBoundStore } from '../../store/useBoundStore';
import { produce } from 'immer';
import axios from 'axios';

const updateAudioNumber = (value: string, name: string, ownerType: string, ownerId: string) => {
  useBoundStore.setState(
    produce((state: RoutingInterface) => {
      state.routing.callUs.phone = value ? value : '';
      state.routing.callUs.name = name ? name : '';
      state.routing.callUs.ownerType = ownerType ? ownerType : '';
      state.routing.callUs.ownerID = ownerId ? ownerId : '';
    }),
  );
};

const updateVideoNumber = (value: string, name: string, ownerType: string, ownerId: string) => {
  useBoundStore.setState(
    produce((state: RoutingInterface) => {
      state.routing.video.phone = value ? value : '';
      state.routing.video.name = name ? name : '';
      state.routing.video.ownerType = ownerType ? ownerType : '';
      state.routing.video.ownerID = ownerId ? ownerId : '';
    }),
  );
};

export const getUserNumber = async (channel: 'audio' | 'video' | 'both') => {
  const viewAsAccount = localStorage.getItem('viewAsAccount');

  try {
    const routingCallUsData = await axios.get(
      `${process.env.REACT_APP_NEXT_API_URL}/v2/genesys_dids?filter[widget_channel_id]=null&page[size]=2${
        viewAsAccount ? `?filter[account_id]=${viewAsAccount}` : ''
      }`,
    );
    if (routingCallUsData) {
      let audioData
      let videoData
      if(channel === 'audio') {
        audioData = routingCallUsData.data.data[0]?.attributes;
      }
      if(channel==='video'){
        videoData = routingCallUsData.data.data[0]?.attributes;
      }
      if(channel==='both'){
        audioData = routingCallUsData.data.data[0]?.attributes;
        videoData = routingCallUsData.data.data[1]?.attributes;
      }

      if (channel === 'audio') {
        updateAudioNumber(
          audioData?.phone_number,
          audioData?.owner_name,
          audioData?.owner_type,
          audioData?.owner_id
        );
      } else if (channel === 'video') {
        updateVideoNumber(
          audioData?.phone_number,
          audioData?.owner_name,
          audioData?.owner_type,
          audioData?.owner_id
        );
      } else if (channel === 'both') {
        updateAudioNumber(
          audioData?.phone_number,
          audioData?.owner_name,
          audioData?.owner_type,
          audioData?.owner_id
        );
        updateVideoNumber(
          videoData?.phone_number,
          videoData?.owner_name,
          videoData?.owner_type,
          videoData?.owner_id
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
};
