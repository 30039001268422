import { useLocation, useSearchParams } from 'react-router-dom';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { produce } from 'immer';

import { aiAllowedLanguages } from './shared/data/aiGenerationAllowedLanguages';
import routesConfig, { signOnManagementRoutes } from 'routes/routesConfig';
import { getUserNumber } from './utils/getWidgetData/getFirstUserNumber';
import { GeneralInterface } from './store/generalStore/GeneralInterface';
import { useLocalStoreData } from 'store/localStoreData/localStoreData';
import { WidgetTextsStoreState } from './store/WidgetTextsDataTypes';
import { AppWrapper, ComponentWithSidebar } from 'styles/AppStyle';
import { localeImports } from './shared/data/NameAndLanguageList';
import { setActiveChannels } from './utils/setActiveChannels';
import { useUserData } from './hooks/useUserData/useUserData';
import { useTextWidgetStore } from './store/widgetTextsData';
import { handleRedirects } from './utils/handleRedirects';
import Loading from 'shared/components/loading/Loading';
import { useBoundStore } from './store/useBoundStore';
import { removeFile } from 'utils/removeFile';
import { resetStore } from 'utils/resetStore';
import en from './locales/en.json';

const LazySidebar = lazy(() => import('components/sidebar/Sidebar'));
const Navbar = lazy(() => import('components/navbar/Navbar'));

function App() {
  const { handleRedirect } = handleRedirects();
  const [searchParams, setSearchParams] = useSearchParams();
  const reload = searchParams.get('reload');

  if (reload) {
    setSearchParams('');
    window.location.reload();
  }

  const sessionUploadedFiles = useLocalStoreData((state) => state.widgetData.sessionUploadedFiles);
  const location = useLocation();
  const currentMainPath = location.pathname.split('/')[1];

  const { userData } = useUserData();
  if (!userData) return;
  const { loggedInUserData, companyAccountData, userLoading } = userData;

  const viewAsAccount = localStorage.getItem('viewAsAccount');

  useEffect(() => {
    if (userLoading) return;
    handleRedirect(loggedInUserData ? loggedInUserData.attributes.role : null, currentMainPath);

    if (location.pathname.includes('/edit/') || location.pathname.includes('/widget/')) {
      return;
    }

    if (sessionUploadedFiles.length > 0) {
      sessionUploadedFiles.map((file) => removeFile(file));
    }

    resetStore();
    if (location.pathname.includes('/new')) {
      updateWidgetLanguage('en');
    }
  }, [loggedInUserData, location.pathname, userLoading]);

  const updateWidgetLanguage = async (value: string) => {
    if (!aiAllowedLanguages.includes(value)) {
      useBoundStore.setState(
        produce((state: GeneralInterface) => {
          state.general.aiHeadsUpTexts = false;
        }),
      );
    }

    let selectedLocale = en;
    if (value in localeImports) {
      selectedLocale = await localeImports[value as keyof typeof localeImports]();
    }

    useBoundStore.setState(
      produce((state: GeneralInterface) => {
        state.general.language.selectedLanguageValue = value;
      }),
    );

    useTextWidgetStore.setState(
      produce((state: WidgetTextsStoreState) => {
        state.texts = selectedLocale.widgetText;
      }),
    );
  };

  useEffect(() => {
    if (loggedInUserData && currentMainPath.includes('new')) {
      const isConnected = companyAccountData?.attributes?.is_connected;
      const enabledFeatures = companyAccountData?.attributes?.enabled_features;

      if (isConnected && enabledFeatures) {
        const { audio, video } = enabledFeatures;

        if (audio !== false && video === false) {
          getUserNumber('audio');
        } else if (audio === false && video !== false) {
          getUserNumber('video');
        } else if (audio !== false && video !== false) {
          getUserNumber('both');
        }
      }
    }
  }, [userLoading, location.pathname]);

  useEffect(() => {
    if (loggedInUserData) {
      if (companyAccountData?.attributes.enabled_features && currentMainPath.includes('new')) {
        setActiveChannels(companyAccountData);
      }
    }
  }, [userLoading, location.pathname]);

  if (userLoading) return null;
  if (
    loggedInUserData?.attributes.role !== 'agency_admin' &&
    loggedInUserData?.attributes.role !== 'superadmin' &&
    currentMainPath === 'resell'
  )
    return null;
  if (viewAsAccount && currentMainPath === 'resell') return null;

  return (
    <AppWrapper>
      {loggedInUserData && (
        <div style={{ paddingTop: '60px' }}>
          <Navbar loggedInUserData={loggedInUserData} companyAccountData={companyAccountData} />
        </div>
      )}
      <ComponentWithSidebar>
        <Suspense fallback={<Loading />}>
          <Routes>
            {routesConfig.map(({ path, hasSidebar, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  <>
                    {hasSidebar && <LazySidebar currentPage={currentMainPath} />}
                    {loggedInUserData && !signOnManagementRoutes.includes(currentMainPath) && element}
                    {!loggedInUserData && signOnManagementRoutes.includes(currentMainPath) && element}
                  </>
                }
              />
            ))}
          </Routes>
        </Suspense>
      </ComponentWithSidebar>
    </AppWrapper>
  );
}

export default App;
